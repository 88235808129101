import { Personnel } from './Personnel';

export class User {
    id?                     : number;
    username                : string;
    password                : string;
    personnel?              : Personnel;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }

}
