import { TypePersonnelService } from "src/app/shared/services/type-personnel.service";
import { property, toClass } from "class-converter";
import { Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import Swal from "sweetalert2";
import { Personnels } from "../shared/model/personnels";
import { Secteur } from "../shared/model/secteur";
import { StatutJuridique } from "../shared/model/StatutJuridique";
import { TypeEtablissement } from "../shared/model/typeEtablissement";
import { Ville } from "../shared/model/ville";
import { EtablissementService } from "../shared/services/etablissement.service";
import { FonctionService } from "../shared/services/fonction.service";
import { GeoService } from "../shared/services/geo.service";
import { SecteurService } from "../shared/services/secteur.service";
import { StatutJuridiqueService } from "../shared/services/statutJuridique.service";
import { UserService } from "../shared/services/user.service";
// import { ExtensionsFile } from "../shared/enum/ExtensionsFile";
import * as extensionsFile from "../shared/const/ExtensionsFile";
import { Etablissements } from "../shared/model/Etablissements";

export class Recaptcha {
  @property("etat", null, true) etat = true;
  @property("recaptcha", null, true) recaptcha: string;
}
@Component({
  selector: "app-demande-inscription",
  templateUrl: "./demande-inscription.component.html",
  styleUrls: ["./demande-inscription.component.scss"],
})
export class DemandeInscriptionComponent implements OnInit {
  isShow: boolean;
  lat = null;
  lng = null;
  ShowMaps: boolean;
  villes: Array<Ville>;
  secteurs: Array<Secteur>;
  statutJuridiques: Array<StatutJuridique>;
  typeEtablissements: Array<TypeEtablissement>;
  fonction: Array<any>;
  codeFonction = "DEMANDEUR";
  siteKey: string;
  personnel = toClass({}, Personnels);
  // tslint:disable-next-line:ban-types
  type: String[] = ["image/png", "image/jpg", "image/jpeg", "application/pdf"];
  fileAccepted = false;
  cpassword: string;
  userNameExsist: boolean;
  onSubmit: boolean;
  recaptcha: string;
  onAccepte: boolean;
  fileRc: File;
  formData: FormData = new FormData();
  step = false;
  file: File = null;
  fileStatut: File = null;
  messages: Array<string> = [];
  close = false;
  existsByMailEtablissement = false;
  existsByPatenteEtablissement = false;
  existsByRsEtablissement = false;
  existsByIfuEtablissement = false;
  existsByIceEtablissement = false;
  existsByMail = false;
  existsByCin = false;
  messsageRc: string;
  messsageStat: string;
  // extensionFile : ExtensionsFile;
  onClear = false;
  canSend = false;
  recaptchaHndeling = toClass({}, Recaptcha);
  engagement = false;
  constructor(
    private geoService: GeoService,
    private secteurService: SecteurService,
    private statutJuridiqueService: StatutJuridiqueService,
    private etablissementService: EtablissementService,
    private userService: UserService,
    private router: Router
  ) {}

  ngOnInit() {
    this.siteKey = "6LdLLoMaAAAAAH9BfAyA-Trd1gdtwCOsknmJ9Mev";
    this.listVille();
    this.listSecteurs();
    this.listStatueJuridique();
    this.listTypeEtablissement();
    navigator.geolocation.getCurrentPosition(
      (response) => {
        this.lat = response.coords.latitude;
        this.lng = response.coords.longitude;
      },
      (error) => {
        this.lat = 34.010576666552566;
        this.lng = -6.855190583834019;
      }
    );
  }
  /* all function of lists  */
  listVille() {
    this.geoService.getVille().subscribe((response: Ville[]) => {
      this.villes = response;
    });
  }
  // end list of villes
  listSecteurs() {
    this.secteurService.getAllSecteurs().subscribe((response: Secteur[]) => {
      console.log(response);
      this.secteurs = response;
    });
  }
  // end list of secteurs
  listStatueJuridique() {
    this.statutJuridiqueService
      .findAll()
      .subscribe((response: StatutJuridique[]) => {
        this.statutJuridiques = response;
      });
  }
  // end of statu juridique
  listTypeEtablissement() {
    this.etablissementService
      .getTypeEtablissement()
      .subscribe((response: TypeEtablissement[]) => {
        this.typeEtablissements = response;
      });
  }
  /* end lists */

  existsByEtablissementEmail(email) {
    this.etablissementService.existsByMail(email).subscribe((response) => {
      if (response) {
        this.existsByMailEtablissement = true;
      } else {
        this.existsByMailEtablissement = false;
      }
    });
  }

  existsByPersonnelEmail(email) {
    this.etablissementService
      .existsByMailPersonnel(email)
      .subscribe((response) => {
        if (response) {
          this.existsByMail = true;
        } else {
          this.existsByMail = false;
        }
      });
  }

  existsByPersonnelCin(email) {
    this.etablissementService
      .existsByCinPersonnel(email)
      .subscribe((response) => {
        if (response) {
          this.existsByCin = true;
        } else {
          this.existsByCin = false;
        }
      });
  }

  existsByEtablissementIfu(ifu) {
    this.etablissementService.existsByIfu(ifu).subscribe((response) => {
      if (response) {
        this.existsByIfuEtablissement = true;
      } else {
        this.existsByIfuEtablissement = false;
      }
    });
  }

  existsByEtablissementlIce(ice) {
    this.etablissementService.existsByIce(ice).subscribe((response) => {
      if (response) {
        this.existsByIceEtablissement = true;
      } else {
        this.existsByIceEtablissement = false;
      }
    });
  }

  existsByEtablissementlRS(rs) {
    this.etablissementService.existsByRS(rs).subscribe((response) => {
      if (response) {
        this.existsByRsEtablissement = true;
      } else {
        this.existsByRsEtablissement = false;
      }
    });
  }

  existsByEtablissementPatent(patent) {
    this.etablissementService.existsByPatente(patent).subscribe((response) => {
      if (response) {
        this.existsByPatenteEtablissement = true;
      } else {
        this.existsByPatenteEtablissement = false;
      }
    });
  }

  nomToRs(nom) {
    if (nom) {
      this.personnel.etablissement.raisonSociale = nom;
      this.existsByEtablissementlRS(this.personnel.etablissement.raisonSociale);
    }
  }

  onChangUpload(file, mode) {
    if (mode === 0) {
      this.file = file[0];
      if (this.file) {
        if (this.file.size <= 2000000) {
          this.messsageRc = null;
          if (this.fileValid(this.file.name)) {
            this.fileRc = this.file;
          } else {
            this.messsageRc =
              // tslint:disable-next-line:max-line-length
              "Format fichier invalide";
          }
          return;
        }
        this.messsageRc =
          // tslint:disable-next-line:max-line-length
          "Registre de commerce volumineux";
      }
    } else {
      if (file[0]) {
        this.messsageStat = null;
        if (this.fileValid(file[0].name)) {
          this.fileStatut = file[0];
        } else {
          this.messsageStat =
            // tslint:disable-next-line:max-line-length
            "Statut Juridique non valid";
        }
      }
    }
  }

  fileValid(fileName): boolean {
    const allowedExtensions = extensionsFile.allowedExtensions;
    const regex = /(?:\.([^.]+))?$/;
    const extension = regex.exec(fileName);
    if (undefined !== extension && null !== extension) {
      if (allowedExtensions.includes(extension[0])) {
        this.fileAccepted = true;
        Swal.fire({
          //  title: 'Etes vous sur ?',
          text: "Pièce jointe chargé avec succès",
          icon: "info",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
        });
        return this.fileAccepted;
      } else {
        this.fileAccepted = false;
        Swal.fire({
          //  title: 'Etes vous sur ?',
          text: "Format Fichier invalide",
          icon: "error",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
        });
        return false;
      }
    }
    return false;
  }

  displayMap() {
    this.isShow = true;
  }

  onChooseLocation(event) {
    this.ShowMaps = true;
    this.lat = event.coords.lat;
    this.lng = event.coords.lng;
    this.personnel.etablissement.coordX = event.coords.lat;
    this.personnel.etablissement.coordY = event.coords.lng;
  }

  demandeInscription() {
    if (
      !this.existsByRsEtablissement &&
      !this.existsByIfuEtablissement &&
      !this.existsByIceEtablissement &&
      !this.existsByMailEtablissement &&
      !this.existsByPatenteEtablissement &&
      !this.existsByCin &&
      !this.existsByMail
    ) {
      // if (this.recaptchaHndeling.recaptcha) {
      this.close = false;
      const fd = new FormData();
      fd.append("personnel", JSON.stringify(this.personnel));
      fd.append("file", this.fileRc);
      fd.append("fileStat", this.fileStatut);
      this.onSubmit = true;
      this.etablissementService.demandeInscription(fd).subscribe(
        (responce) => {
          if (responce.success) {
            this.onSubmit = false;
            this.cls();
            this.sweetMessage(
              "Votre demande a éte bien envoyée",
              "<b>en attendant la validation </b>"
            );
            return;
          } else {
            this.messages = responce.messages;
            this.close = true;
          }
          this.onSubmit = false;
        },
        (err) => {
          this.onSubmit = false;
          Swal.fire(
            "l'enregistrement a échoué ",
            "veuillez réessayer",
            "error"
          );
          // this.cls();
        }
      );
      /*} else {
        Swal.fire('Attention', 'Merci de vérifier vous captcha', 'info');
      }*/
    } else {
      Swal.fire("Attention", "veuillez vérifier vos champs", "info");
    }
  }

  checkUserName() {
    this.userService
      .checkUserName(this.personnel.user.username)
      .subscribe((responce) => {
        if (responce) {
          this.userNameExsist = true;
          console.log(responce);
          return;
        }
        this.userNameExsist = false;
      });
  }

  cls() {
    this.onClear = true;
    this.personnel = toClass(toClass({}, Personnels), Personnels);
  }

  sweetMessage(titre: string, msg: string) {
    // tslint:disable-next-line:prefer-const
    let timerInterval;
    Swal.fire({
      title: titre,
      html: msg,
      timer: 6000,
      willClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.timer || result.dismiss) {
        this.cls();
        this.router.navigateByUrl("/login");
      } else {
        this.cls();
        this.router.navigateByUrl("/login");
      }
    });
  }

  onAccepted(isChecked: boolean) {
    this.onAccepte = isChecked;
  }

  uploadFile(id) {
    if (this.fileRc) {
      this.formData.append("file", this.fileRc);
      this.etablissementService
        .uploadEtablissementRc(id, this.formData)
        .subscribe(() => {
          this.formData.delete("file");
        });
    }
  }

  selectedSecteur() {
    if (this.personnel.etablissement.secteur) {
      if (this.personnel.etablissement.secteur.code === "PUBLIC") {
        this.fileStatut = null;
        this.fileRc = null;
        return false;
      }
      return true;
    }
    return false;
  }

  steper() {
    this.step = true;
  }
  steperOld() {
    this.step = false;
  }
  canSubmit() {
    return (
      this.userNameExsist ||
      !this.onAccepte ||
      // !this.recaptcha ||
      this.existsByPatenteEtablissement ||
      this.existsByMailEtablissement ||
      this.existsByMail ||
      this.existsByCin ||
      this.existsByRsEtablissement ||
      this.existsByIfuEtablissement ||
      this.existsByIceEtablissement ||
      !this.engagement
    );
  }
  cinUperCase() {
    this.personnel.cin = this.personnel.cin.toUpperCase();
  }
  handleSuccess(e) {
    this.recaptchaHndeling.recaptcha = e;
    setTimeout(() => {
      this.recaptchaHndeling.etat = false;
    }, 1000);
  }
  handleReset() {
    this.recaptchaHndeling = toClass({ etat: true }, Recaptcha);
  }
  handleExpire() {
    this.recaptchaHndeling = toClass({ etat: true }, Recaptcha);
  }
  handleLoad() {
    this.recaptchaHndeling = toClass({ etat: true }, Recaptcha);
  }
  onEngagement(onEngagement) {
    this.engagement = onEngagement;
  }
}
