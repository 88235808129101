import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { StatutJuridique } from "../model/StatutJuridique";

@Injectable({
  providedIn: "root",
})
export class StatutJuridiqueService {
  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) {}

  getById(id): Observable<any> {
    console.log("statusJuridiques ===> dans StatusJuridiquesService");

    return this.http.get(this.baseUrl + "/statutJuridique/getOne/" + id);
  }

  findAll(): Observable<any> {
    return this.http.get(`${this.baseUrl}/api/statutJuridique/findAll`);
  }
}
