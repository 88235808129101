import { Observable } from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { environment } from 'src/environments/environment';



@Injectable({
    providedIn: 'root'
})
export class UserService {

    baseUrl = environment.baseUrl;

    constructor(private http: HttpClient) {
    }

    getList(): Observable<any> {
        return this.http.get(this.baseUrl + '/users/list');
    }

    getListDemande(): Observable<any> {
        return this.http.get(this.baseUrl + '/users/listdemande');
    }

    getListByCriteria(firstName, lastName, role_id): Observable<any> {
        return this.http.get(this.baseUrl + '/users/list?firstName=' + firstName + '&lastName=' + lastName + '&role_id=' + role_id);
    }

    getUserByUsername(username: String): Observable<any> {
        return this.http.get(this.baseUrl + '/users/getUserByUsername/' + username);
    }

    updateRole(user_id, role_id): Observable<any> {
        return this.http.put(this.baseUrl + '/users/updateRole', {user_id, role_id});
    }

    addRole(user): Observable<any> {
        return this.http.post(this.baseUrl + '/users/add', user);
    }

    delete(id: number) {
        return this.http.delete(this.baseUrl + '/users/delete/' + id);
    }

    updateUser(user){
        return this.http.put(this.baseUrl + '/users/update/'+user.id,user);
    }

    checkEtablissement(id){
        return this.http.get(`${this.baseUrl}/users/checkEtablissement/${id}`);  
    }

    checkUserName(name){
        return this.http.get(`${this.baseUrl}/api/usernameExist/${name}`);  
    }
    
    archives(id){
        return this.http.get(`${this.baseUrl}/users/archives/${id}`);  
    }

    approuveDemandes(id){
        return this.http.get(`${this.baseUrl}/users/approuveDemande/${id}`); 
    }

    demandeInscription(user){
        return this.http.post(this.baseUrl + '/users/inscreption', user);  
    }

    findUserById(userId): Observable<any> {
        return this.http.get(this.baseUrl + '/users/' + userId);
    }
}
