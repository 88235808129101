import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { LoginService } from "./login.service";

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let authReq = req;
    const token = localStorage.getItem("auth-token");
    if (token != null) {
      authReq = req.clone({
        headers: new HttpHeaders({
          Authorization: "Bearer " + token,
        }),
      });
      return next.handle(authReq).pipe(
        catchError((error) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 0) {
              //this.router.navigateByUrl('/server-down');
              return throwError(error);
            } else if (error.status === 401) {
              this.router.navigateByUrl("/login?session=expired");
              return throwError(error);
            } else {
              return throwError(error);
            }
          }
        })
      );
    }
    return next.handle(req);
  }
}
