import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import { environment } from 'src/environments/environment';
import { Pays } from '../model/pays';



@Injectable({
    providedIn: 'root'
})
export class GeoService {

    // Define API
    baseUrl = environment.baseUrl;
    constructor(private http: HttpClient) {
    }

    getPays(params?: any): Observable<any> {
        return this.http.get(this.baseUrl + '/payses', {params});
    }

    getRegions(): Observable<any> {
        return this.http.get(this.baseUrl + '/geo/region/list');
    }

    getFullRegions(): Observable<any> {
        return this.http.get(this.baseUrl + '/geo/region/full-list');
    }

    findVilleByCriteria(name, provinceId): Observable<any> {
        return this.http.get(this.baseUrl + '/geo/ville/list?ville_nom=' + name + '&province_id=' + provinceId);
    }
    getVille(): Observable<any> {
        return this.http.get(this.baseUrl + '/api/ville/findAll');
    }

    findVilleById(id): Observable<any> {
        return this.http.get(this.baseUrl + '/geo/ville/' + id);
    }

    addVille(city): Observable<any> {
        return this.http.post(this.baseUrl + '/geo/ville/add', city);
    }

    getCommune(): Observable<any> {
        return this.http.get(this.baseUrl + '/geo/commune/list');
    }

    findCommuneByCriteria(nom, provinceId): Observable<any> {
        return this.http.get(this.baseUrl + '/geo/commune/list?commune_nom=' + nom + '&province_id=' + provinceId);
    }

    addRegion(region): Observable<any> {
        return this.http.post(this.baseUrl + '/geo/region/add', region);
    }

    updateRegion(region): Observable<any> {
        return this.http.put(this.baseUrl + '/geo/region/update', region);
    }

    deleteRegion(id): Observable<any> {
        return this.http.delete(this.baseUrl + '/geo/region/delete/' + id);
    }

    getProvinces(): Observable<any> {
        return this.http.get(this.baseUrl + '/provinces');
    }

    findProvinceByCriteria(name, regionId): Observable<any> {
        return this.http.get(this.baseUrl + '/geo/province/list?name=' + name + '&region_id=' + regionId);
    }

    addProvince(province): Observable<any> {
        return this.http.post(this.baseUrl + '/geo/province/add', province);
    }


    addPays(pays: Pays) {
        return this.http.post(this.baseUrl + '/geo/pays/add', pays);
    }

    updatePays(pays: Pays) {
        return this.http.put(`${this.baseUrl}/geo/pays/update/${pays.id}`, pays);
    }


    deletePays(id) {
        return this.http.delete(`${this.baseUrl}/geo/pays/delete/${id}`);
    }




    updateProvince(province): Observable<any> {
        return this.http.put(this.baseUrl + '/geo/province/update', province);
    }

    deleteProvince(id): Observable<any> {
        return this.http.delete(this.baseUrl + '/geo/province/delete/' + id);
    }

    updateVille(city): Observable<any> {
        return this.http.put(this.baseUrl + '/geo/ville/update', city);
    }

    deleteVille(id): Observable<any> {
        return this.http.delete(this.baseUrl + '/geo/ville/delete/' + id);
    }


    addCommune(commune): Observable<any> {
        return this.http.post(this.baseUrl + '/geo/commune/add', commune);
    }

    updateCommune(commune): Observable<any> {
        return this.http.put(this.baseUrl + '/geo/commune/update', commune);
    }

    deleteCommune(id): Observable<any> {
        return this.http.delete(this.baseUrl + '/geo/commune/delete/' + id);
    }

    getListObject(urlLink: string): Observable<any> {
        return this.http.get(urlLink);
    }


}
