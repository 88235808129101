import { AgmCoreModule } from '@agm/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatDatepickerModule,
  MatDialogModule,
  MatFormFieldModule,
  MatIconModule,
  MatStepperModule,
} from '@angular/material';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxCaptchaModule } from 'ngx-captcha';
import { NgxPaginationModule } from 'ngx-pagination';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DemandeInscriptionComponent } from './demande-inscription/demande-inscription.component';
import { PrivacyPolicyComponent } from './demande-inscription/privacy-policy/privacy-policy.component';
import { DialogComponent } from './layout/components/dialog/dialog.component';
import { AuthGuard, PageHeaderModule } from './shared';
import { HttpInterceptorService } from './shared/services/HttpInterceptor.service';
import { InputMaskFormaterDirective } from './shared/directives/input-mask-formater.directive';
import { LoadingFormComponent } from './shared/loading-form/loading-form.component';

@NgModule({
  declarations: [
    AppComponent,
    DialogComponent,
    PrivacyPolicyComponent,
    DemandeInscriptionComponent,
    // LoginComponent,
    // LogoutComponent
  ],
  imports: [
    MatSliderModule,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxPaginationModule,
    MatStepperModule,
    MatDialogModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    PageHeaderModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatTabsModule,
    MatAutocompleteModule,
    MatSelectModule,
    NgxCaptchaModule,
    MatProgressBarModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDzQCSnHbF6GZXW2A6vvKAFRyCCl7lCtEs',
    }),

    // BsDatepickerModule.forRoot(),
  ],
  entryComponents: [DialogComponent],
  providers: [
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
