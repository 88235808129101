import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './shared';
import { DemandeInscriptionComponent } from './demande-inscription/demande-inscription.component';
import { PrivacyPolicyComponent } from './demande-inscription/privacy-policy/privacy-policy.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./layout/layout.module').then(
        (m) => m.LayoutModule
      ) /*, canActivate: [AuthGuard]*/,
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginModule),
  },
  // {path: 'members', loadChildren: () => import('./login/login.module').then(m => m.LoginModule)},
  {
    path: 'error',
    loadChildren: () =>
      import('./server-error/server-error.module').then(
        (m) => m.ServerErrorModule
      ),
  },
  {
    path: 'access-denied',
    loadChildren: () =>
      import('./access-denied/access-denied.module').then(
        (m) => m.AccessDeniedModule
      ),
  },
  { path: 'demande-inscription', component: DemandeInscriptionComponent },
  { path: 'politique-de-confidentialite', component: PrivacyPolicyComponent },
  {
    path: 'not-found',
    loadChildren: () =>
      import('./not-found/not-found.module').then((m) => m.NotFoundModule),
  },
  {
    path: 'server-down',
    loadChildren: () =>
      import('./server-error/server-error.module').then(
        (m) => m.ServerErrorModule
      ),
  },
  { path: '**', redirectTo: 'not-found' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
