import { toPlain, property } from 'class-converter';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TypeEtablissement } from '../model/typeEtablissement';
import { Personnel } from '../model/Personnel';
class SearchCriteria {
  @property('raisonSociale', null, true) raisonSociale;
  @property('code', null, true) code;
  @property('rc', null, true) rc;
}
@Injectable({
  providedIn: 'root',
})
export class EtablissementService {
  constructor(private http: HttpClient) {}
  // Define API
  baseUrl = environment.baseUrl;
  readonly API_ETABLISSEMENT_SEARCH = `${environment.baseUrl}/etablissement/find`;
  s;

  getEtablissement(): Observable<any> {
    return this.http.get(this.baseUrl + '/etablissement/listetablissement');
  }

  existsByMailPersonnel(email: string): Observable<any> {
    return this.http.get(
      this.baseUrl + '/personnels/search/existsByEmail?email=' + email
    );
  }

  existsByCinPersonnel(email: string): Observable<any> {
    return this.http.get(
      this.baseUrl + '/personnels/search/existsByCin?cin=' + email
    );
  }

  existsByMail(email: string): Observable<any> {
    return this.http.get(
      this.baseUrl + '/etablissements/search/existsByMail?mail=' + email
    );
  }

  existsByIfu(ifu: string): Observable<any> {
    return this.http.get(
      this.baseUrl + '/etablissements/search/existsByIfu?ifu=' + ifu
    );
  }

  existsByIce(ifu: string): Observable<any> {
    return this.http.get(
      this.baseUrl + '/etablissements/search/existsByIce?ice=' + ifu
    );
  }

  existsByRS(rs: string): Observable<any> {
    return this.http.get(
      this.baseUrl +
        '/etablissements/search/existsByRaisonSociale?raisonSociale=' +
        rs
    );
  }

  existsByPatente(patente: string): Observable<any> {
    return this.http.get(
      this.baseUrl + '/etablissements/search/existsByPatente?patente=' + patente
    );
  }

  getTypeEtablissement(): Observable<any> {
    return this.http.get(this.baseUrl + '/api/typeEtablissement/findAll');
  }

  addEtablissement(etablissement): Observable<any> {
    return this.http.post(this.baseUrl + '/etablissement/add', etablissement);
  }

  addTypeEtablissement(typeEtablissement: TypeEtablissement): Observable<any> {
    return this.http.post(
      this.baseUrl + '/typeEtablissements',
      typeEtablissement
    );
  }

  updateEtablissement(etablissement): Observable<any> {
    return this.http.put(this.baseUrl + '/etablissement/update', etablissement);
  }

  updateTypeEtablissement(
    url: string,
    typeEtablissement: TypeEtablissement
  ): Observable<TypeEtablissement> {
    return this.http.put<TypeEtablissement>(url, typeEtablissement);
  }

  deleteEtablissement(id): Observable<any> {
    return this.http.delete(this.baseUrl + '/etablissement/delete/' + id);
  }

  deleteTypeEtablissement(url: string): Observable<TypeEtablissement> {
    return this.http.delete<TypeEtablissement>(url);
  }

  findEtablissementByCriteria(
    rc,
    nom,
    typeEtablissement,
    activiteId
  ): Observable<any> {
    return this.http.get(
      this.baseUrl +
        '/etablissement/list?rc=' +
        rc +
        '&nom=' +
        nom +
        '&activiteId=' +
        activiteId +
        '&typeEtablissement= ' +
        typeEtablissement
    );
  }

  findTypeEtablissementByCriteria(name): Observable<any> {
    return this.http.get(
      this.baseUrl + '/etablissement/type/list?name=' + name
    );
  }

  findEtablissementByRc(rc: string): Observable<any> {
    return this.http.get(this.baseUrl + '/etablissement/' + rc);
  }

  findEtablissementByRcOrCode(params: any): Observable<any> {
    // url.searchParams.set('rc', params.rc);
    // url.searchParams.set('code', params.code);
    const requestParams = this.setUrlParams(params);
    return this.http.get(
      this.baseUrl + '/etablissement/search/' + requestParams
    );
  }
  findEtablissementByCodeAndRc(code: string, rc: string): Observable<any> {
    // -------------------
    return this.http.get(
      this.baseUrl + '/etablissement/?code=' + code + '&rc=' + rc
    );
  }

  findEtablissementById(id: number): Observable<any> {
    return this.http.get(this.baseUrl + '/etablissement/find/' + id);
  }

  findEtablissementBy(params: any): Observable<any> {
    const requestParams = this.setUrlParams(params);
    return this.http.get(this.baseUrl + '/etablissement/list/' + requestParams);
  }

  setUrlParams(params: any) {
    const entries = Object.entries(params).filter(
      (entry) => entry[1] !== null && entry[1] !== ''
    );
    if (entries.length > 0) {
      let requestParams = '?';
      for (const entry of entries) {
        const predicate = entries.indexOf(entry) < entries.length - 1;
        const param = entry[0] + '=' + entry[1];
        requestParams += predicate ? param + '&' : param;
      }
      return requestParams;
    } else {
      return '';
    }
  }

  findEtabByDepartementId(id: number): Observable<any> {
    return this.http.get(this.baseUrl + '/etablissement/find?departId=' + id);
  }

  findEtablissementForInspectionById(id: number): Observable<any> {
    return this.http.get(this.baseUrl + '/etablissement/findEtab/' + id);
  }

  uploadEtablissementRc(id: number, file: FormData): Observable<any> {
    return this.http.post(`${this.baseUrl}/etablissement/uploadRc/${id}`, file);
  }

  downloadRc(id: number): Observable<Blob> {
    return this.http.get(`${this.baseUrl}/etablissement/downloadFile/${id}`, {
      responseType: 'blob',
    });
  }

  demandeInscription(body): Observable<any> {
    return this.http.post<Personnel>(`${this.baseUrl}/api/inscription`, body);
  }

  getListObject(urlLink: string): Observable<any> {
    return this.http.get(urlLink);
  }

  getListTypesActivites() {
    return this.http.get(this.baseUrl + '/typeActivites');
  }

  findEtabByRcOrCode(searchRequest: SearchCriteria): Observable<any> {
    return this.http.get(this.baseUrl + '/etablissement/search/multiple', {
      params: toPlain(searchRequest, SearchCriteria),
    });
  }

  findEtabByUserAuth(params: any): Observable<any> {
    return this.http.get(this.baseUrl + '/personnels/search/findByUserId', {
      params: { id: params },
    });
  }
  findByHref(href) {
    return this.http.get(href);
  }
  getListSecteurs() {
    return this.http.get(this.baseUrl + '/secteurs');
  }

  getCategories() {
    return this.http.get(this.baseUrl + '/categories');
  }

  findCurrent(): Observable<any> {
    return this.http.get(this.baseUrl + '/etablissement/current');
  }

  updateProfil(form, id): Observable<any> {
    return this.http.put(this.baseUrl + '/etablissement/profil/' + id, form);
  }

  findOneByRSOrCode(searchRequest: SearchCriteria): Observable<any> {
    return this.http.get(this.baseUrl + '/etablissement/search/one', {
      params: toPlain(searchRequest, SearchCriteria),
    });
  }
}
