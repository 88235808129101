import { defaultVal, property } from "class-converter";
import { VilleModel } from "src/app/autorisation/demande-autorisation-form/models/ville.model";
import { Departement } from "./departement";
import { Secteur } from "./secteur";
import { StatutJuridique } from "./StatutJuridique";
import { TypeEtablissement } from "./typeEtablissement";
import { Ville } from "./ville";

export class Etablissements {
  @defaultVal(null) @property("id", null, true) id?: number;
  @defaultVal(null)
  @property("raisonSociale", null, true)
  raisonSociale: string;
  @defaultVal(null) @property("nom", null, true) nom: string;
  @defaultVal(null) @property("adresse", null, true) adresse: string;
  @defaultVal(null) @property("tel", null, true) tel: string;
  @defaultVal(null) @property("fax", null, true) fax: string;
  @defaultVal(null) @property("mail", null, true) mail: string;
  @defaultVal(null) @property("siteweb", null, true) siteweb: string;
  @defaultVal(null) @property("patente", null, true) patente: string;
  @defaultVal(null) @property("ifu", null, true) ifu: string;
  @defaultVal(null) @property("ice", null, true) ice: string;
  @defaultVal(null) @property("agrement", null, true) agrement: string;
  @property("statPath", null, true) statPath: string;
  @property("rsPath", null, true) rsPath: string;
  @defaultVal(null)
  @property("numAgrement", null, true)
  numAgrement: string;
  @property("coordX", null, true) coordX: number;
  @property("coordY", null, true) coordY: number;
  @defaultVal(null) @property("code", null, true) code: string;
  @defaultVal(null) @property("statut", null, true) statut: string;
  @defaultVal(null) @property("autoExercice", null, true) autoExercice: string;
  @defaultVal(null)
  @property("departements", null, true)
  departements: Departement[];
  @defaultVal({})
  @property("statutJuridique", null, true)
  statutJuridique: StatutJuridique;
  @defaultVal({}) @property("ville", null, true) ville: VilleModel;
  @defaultVal(null)
  @defaultVal({})
  @property("secteur", null, true)
  secteur: Secteur;
  @defaultVal(null)
  @defaultVal({})
  @property("typeEtablissement", null, true)
  typeEtablissement: TypeEtablissement;
}
