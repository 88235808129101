import { QualificationModel } from "src/app/autorisation/demande-autorisation-form/models/qualification.model";
import { defaultVal, property } from "class-converter";
import { Fonction } from "./fonction";
import { Etablissements } from "./Etablissements";
import { User } from "./user";
import { ReferentialModel } from "src/app/autorisation/demande-autorisation-form/models/Referential.model";

export class Personnels {
  @defaultVal(null) @property("id", null, true) id?: number;
  @defaultVal(null) @property("code", null, true) code: string;
  @defaultVal(null) @property("prenom", null, true) prenom: string;
  @defaultVal(null) @property("nom", null, true) nom: string;
  @defaultVal(null) @property("cin", null, true) cin: string;
  @defaultVal(null) @property("fax", null, true) fax: string;
  @defaultVal(null)
  @property("dateNaissance", null, true)
  dateNaissance: string;
  @defaultVal(null)
  @property("lieuNaissance", null, true)
  lieuNaissance: string;
  @defaultVal(null) @property("titre", null, true) titre: string;
  @defaultVal(null) @property("affectation", null, true) affectation: string;
  @defaultVal(null)
  @property("nbrMinutesMois", null, true)
  nbrMinutesMois: string;
  @defaultVal(null) @property("tel", null, true) tel: string;
  @defaultVal(null) @property("email", null, true) email: string;
  @defaultVal(null) @property("tempPlien", null, true) tempPlien = false;
  @defaultVal(null) @property("adresse", null, true) adresse: string;
  @defaultVal(null) @property("adressePro", null, true) adressePro: string;
  @defaultVal(null) @property("dateEngage", null, true) dateEngage: string;
  @defaultVal(null) @property("observation", null, true) observation: string;
  @defaultVal(null) @property("qualite", null, true) qualite: string;
  @defaultVal(null) @property("service", null, true) service: string;
  @defaultVal(null) @property("specialite", null, true) specialite: string;
  @defaultVal(null) @property("fonction", null, true) fonction: string;
  @defaultVal(null)
  @property("distanceLieuTravail", null, true)
  distanceLieuTravail: string;
  @defaultVal(null) @property("profession", null, true) profession: string;
  @defaultVal({})
  @property("departement", ReferentialModel, true)
  departement: ReferentialModel;
  @defaultVal({})
  @property("typePersonnel", null, true)
  typePersonnel: ReferentialModel;
  @defaultVal({})
  @property("qualification", null, true)
  qualification: QualificationModel;
  @property("user", null, true) user: User = new User();
  @defaultVal({})
  @property("etablissement", Etablissements, true)
  etablissement?: Etablissements;
}
