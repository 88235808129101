import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import { Secteur } from '../model/secteur';
import { Categorie } from '../model/categorie';
import { Installation } from '../model/installation';
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root'
})
export class SecteurService {

    baseUrl = environment.baseUrl;

    constructor(private http: HttpClient) { 
    }

    /******** Secteur ********/
    getAllSecteurs(): Observable<any> {
        return this.http.get(this.baseUrl + '/api/secteur/findAll');
    }

    addSecteur(secteur: Secteur): Observable<any> {
        return this.http.post(this.baseUrl + '/secteur/add',secteur);
    }

    updateSecteur(secteur: Secteur): Observable<any> {
        return this.http.put(this.baseUrl + '/secteur/update',secteur);
    }

    deleteSecteur(id: number): Observable<any> {
        return this.http.delete(this.baseUrl + '/secteur/delete/' + id);
    }
    
    /******** Categorie ********/
    getAllCategories(): Observable<any> {
        return this.http.get(this.baseUrl + '/categorie/list');
    }

    addCategorie(categorie: Categorie): Observable<any> {
        return this.http.post(this.baseUrl + '/categorie/add',categorie);
    }

    updateCategorie(categorie: Categorie): Observable<any> {
        return this.http.put(this.baseUrl + '/categorie/update',categorie);
    }

    deleteCategorie(id: number): Observable<any> {
        return this.http.delete(this.baseUrl + '/categorie/delete/' + id);
    }

    /******** Installation ********/
    getAllInstallations(): Observable<any> {
        return this.http.get(this.baseUrl + '/installation/list');
    }

    addInstallation(installation: Installation): Observable<any> {
        return this.http.post(this.baseUrl + '/installation/add',installation);
    }

    updateInstallation(installation: Installation): Observable<any> {
        return this.http.put(this.baseUrl + '/installation/update',installation);
    }

    deleteInstallation(id: number): Observable<any> {
        return this.http.delete(this.baseUrl + '/installation/delete/' + id);
    }

    
}