import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

const AUTH_API = environment.baseUrl;

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private roles = new BehaviorSubject([]);

  constructor(private http: HttpClient, public router: Router) {}

  login(credentials): Observable<any> {
    return this.http.post(
      `${AUTH_API}/api/auth/signin`,
      {
        username: credentials.username,
        password: credentials.password,
      },
      httpOptions
    );
  }

  logOut() {
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  loggedIn() {
    return !!localStorage.getItem('auth-token');
  }

  getUserAuth() {
    return localStorage.getItem('auth-user');
  }
  setRoles(roles) {
    this.roles.next(roles);
  }
  get getRoles() {
    return this.roles.getValue();
  }
}
